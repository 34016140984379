<script setup lang="ts">
import { ref } from "vue";
import { KippieButton } from "../";

defineEmits(["submit"]);
interface Props {
	title: string;
	text: string;
	emailPlaceholder: string;
	buttonLabel: string;
}

const { title, text, emailPlaceholder, buttonLabel } = defineProps<Props>();

const email = ref<string>("");
</script>

<template>
	<section class="bg-sand border-t border-brown py-10">
		<div class="container flex flex-col lg:flex-row justify-between gap-y-4 gap-x-8">
			<div class="space-y-4">
				<h2 class="font-brush text-5xl leading-50">
					{{ title }}
				</h2>
				<p class="text-base leading-7">
					{{ text }}
				</p>
			</div>
			<form
				class="space-y-4 grow min-w-[200px] lg:max-w-md"
				@submit.prevent="
					() => {
						$emit('submit', email);
						email = '';
					}
				"
			>
				<input
					v-model="email"
					:placeholder="emailPlaceholder"
					required
					type="email"
					class="outline-black-light w-full p-4 rounded-lg text-gray"
				/>
				<slot name="button" v-bind="{ buttonLabel }">
					<KippieButton color="black" type="submit" block>
						{{ buttonLabel }}
					</KippieButton>
				</slot>
			</form>
		</div>
	</section>
</template>
